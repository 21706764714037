<template>
    <div class="chat_wrapper">
       <div class="chat">
           <h1 class="chat_title">Message</h1>
           <p class="chat_text date"> {{ message.creationDate }} </p>
           <div class="chat_text letter">
               {{ message.description }}
           </div>
           <form action="" @submit.prevent="sendMessage">

               <md-field class="custom-textarea">
                   <label>Texte de message</label>
                   <md-textarea
                       type="text"
                       v-model="answer.description"
                       placeholder="Votre message"
                   />
               </md-field>

               <button type="submit" class="btn-send" :class="inPending ? 'disable-btn' : ''" :disabled="inPending">Envoyer</button>

               <Errors :errors="errors"></Errors>

           </form>

       </div>
    </div>
</template>
<script>
import Errors from "@/components/Errors"
import {errors} from "@/mixins/errors"
import {mapActions} from "vuex";

export default {
    name: 'chat',

    data: () => ({
        inPending: false,
        message: {
            description: '',
            creationDate: ''
        },
        answer: {
            id: 0,
            description: ''
        }
    }),

    components: {
        Errors
    },

    mixins: [
        errors,
    ],

    async mounted() {
       await this.getMessageData()
    },

    methods: {
        ...mapActions(['getMessages']),
        async getMessageData() {
//           const id = this.$route.params.id
//            await this.$axios
//               .get(process.env.VUE_APP_API_URL + `/bgfMessage/${id}`)
//               .then(({data}) => {
//                   this.message.description = data.description
//                   this.message.creationDate = data.creationDate ? this.$moment(data.creationDate).format('YYYY-MM-DD HH:mm:ss') : ''
//               })
        },
        sendMessage() {
//            this.clearErrors()
//            this.inPending = true
//            this.$axios
//                .post(process.env.VUE_APP_API_URL + '/bgfMessage/Edit', this.answer)
//                .then((response) => {
//                    if(response.data.success) {
//                        this.inPending = false
//                        this.getMessages()
//                        this.$router.push('/messages')
//                    } else {
//                        this.setErrors([response.data.error])
//                    }
//                })
//                .catch(err => {
//                    this.setErrors(err.response.data.errors)
//                })
        }
    }
}
</script>

<style lang="scss" scoped>
.disable-btn {
  background-color: #aaafbd!important;
}
.chat {
    &_wrapper {
        padding-left: 30px;
    }
    &_title {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        margin-bottom: 15px;
    }
    &_text {
        width: 100%;
        max-width: 760px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
    .date {
        margin-bottom: 25px;
    }
    .letter {
        padding-bottom: 35px;
        border-bottom: 1px solid #000000;
        margin-bottom: 55px;
    }
    .custom-textarea{
        border: 1px solid #CDD0D9;
        max-width: 760px;
        height: 220px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        padding: 0;
        margin-bottom: 20px;

        label {
            opacity: 1;
            font-size: 16px;
            line-height: 24px;
            color: black;
            top: -25px!important;
            left: 0;
        }
        textarea {
            height: 100%;
            padding: 20px 25px!important;
        }
    }
    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
        border: none;
    }
    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
        border: none;
    }
    .btn-send {
        width: 120px;
        height: 48px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        border: none;
        cursor: pointer;
        color: #FFFFFF;
        background-color: #0045FF;
    }
}
</style>

